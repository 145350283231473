import axios from '@axios'

const timeout = 60000

const exportSalesReport = (params = {}) =>
  axios
    .post(
      '/merchant/orders/export.xlsx',
      { ...params, _method: 'GET' },
      {
        responseType: 'blob',
        timeout,
      },
    )
    .then(({ data }) => {
      const xlsxBlobUrl = window.URL.createObjectURL(new Blob([data], { type: 'application/vnd.ms-excel' }))
      window.open(xlsxBlobUrl)
    })

export default {
  exportSalesReport,
}
